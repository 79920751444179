<template>
  <div v-if="inShow" class="AD">
    <div class="showAD pointer" @click="clickAdvertising">
      <div class="countdown" v-if="isTime">剩余时间 {{ countdownTime }}</div>
      <div v-if="props.model == 'picture'">
        <img style="margin-bottom: -4px;width: 100%;" :src="propagandaText" alt="" />
      </div>
      <div v-if="props.model == 'writing'">
        <div v-html="propagandaText"></div>
      </div>
    </div>
    <div class="close pointer" @click="close">×</div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue'
import { setAD } from '@/utils/auth'
import { now } from 'moment'
import { DArrowLeft } from '@element-plus/icons'
const emits = defineEmits(['click'])
const props = defineProps({
  show: {
    type: Boolean,
    default: true
  },
  propagandaText: {
    type: String,
    default: '<h1>宣传标语</h1>'
  },
  alt: {
    type: String,
    default: '图片'
  },
  isTime: {
    type: Boolean,
    default: true
  },
  model: {
    type: String,
    default: 'writing'
  },
  endText: {
    type: String,
    default: '活动已结束'
  },
  endTime: {
    type: String,
    default: ''
  },
  goUrl: {
    type: String,
    default: ''
  }
})
var countdownTime = ref(0)
const inShow = ref(props.show)

const close = () => {
  inShow.value = false
  setAD()
}

const calcDate = (d) => {
  // 传递时间解构成时间戳
  const end = Date.parse(new Date(d))
  return () => {
    // 当前时间戳
    const now = Date.now()
    const difftime = (end - now) / 1000 // 时间差的秒数
    if (difftime <= 0) {
      return false // 如果时间到了就返回false
    }
    // 没有结束计算时间 几天 几时 几分 几秒
    var days = parseInt(difftime / 86400)
    var hours = parseInt(difftime / 3600) - 24 * days
    var minutes = parseInt((difftime % 3600) / 60)
    var seconds = parseInt(difftime % 60) // 以60秒为一整份 取余 剩下秒数
    var time = () => {
      if (days) {
        return `${days}天${hours}小时${minutes}分钟${seconds}秒`
      } else if (hours) {
        return `${hours}小时${minutes}分钟${seconds}秒`
      } else if (minutes) {
        return `${minutes}分钟${seconds}秒`
      } else if (seconds) {
        return `${seconds}秒`
      }
    }
    return time()
    // 返回结束时间
  }
}

var down = calcDate(props.endTime)

const start = () => {
  const setIntfun = setInterval(() => {
    countdownTime.value = down()
    if (!down()) {
      clearInterval(setIntfun)
      countdownTime.value = props.endText
    }
  }, 1000)
}

if (props.isTime) {
  start()
}
watch(
  () => props.endTime,
  (val) => {
    down = calcDate(val)
    start()
  },
  {
    immediate: true
  }
)
const clickAdvertising = async () => {
  var data = {
    underway: false
  }
  // 如果有倒计时广告
  if (props.isTime && props.endTime) {
    data = {
      underway: true,
      time: props.endTime
    }
  }
  await emits('click', data)
}
</script>

<style lang="scss">
@import '@/common/rem';
.AD {
  width: 100%;
  min-width: 1200px;
  // height: 80px;
  overflow: hidden;
  position: relative;
  .showAD {
    width: 100%;
  }
  .countdown {
    position: absolute;
    padding: 10px;
    color: #fff;
    font-weight: 900;
    top: 0.6rem;
    left: 0.5rem;
    img {
      width: 100%;
    }
  }
  .close {
    top: 0;
    right: 0;
    color: #fff;
    font-weight: bold;
    // padding: 0.5rem;
    font-size: 18px;
    padding-left: 6px;
    padding-right: 6px;
    position: absolute;
    background: rgba(1, 1, 1, 0.26);
  }
}
</style>
