import router from './router'
import store from './store'
// import blackList from './utils/routerblackList'
// blackList.indexOf(to.path) > -1 ||
/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
  // 存在 token ，进入主页
  if (store.getters.token) {
    // 如果登录了就不可以再去看登录
    if (to.path === '/login') {
      next('/')
    } else {
      // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
      if (to.matched.length === 0) {
        next('/404')
      }
      // 判断用户资料是否获取
      // 若不存在用户信息，则需要获取用户信息
      if (!store.getters.hasUserInfo) {
        // 触发获取用户信息的 action，并获取用户当前权限
        await store.dispatch('user/getUserInfo')
        // 添加完动态路由之后，需要在进行一次主动跳转
        return next(to.path)
      }
      next()
    }
  } else {
    // 没有token的情况下，看页面是否需要登录
    if (to.meta.requireAuth) {
      next(`/login?redirect=${to.fullPath}`)
    } else {
      if (to.matched.length === 0) {
        next('/404')
      }
      next()
    }
  }
  document.documentElement.scrollTop = 0
})
