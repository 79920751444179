<template>
  <!-- 基础布局 layout => basics -->
  <Header></Header>
  <router-view v-slot="{ Component }">
    <transition name="slide-left">
      <div>
        <component :is="Component" />
      </div>
    </transition>
  </router-view>
  <Footer class="bottom"></Footer>
  <Right></Right>
</template>

<script setup>
import { ref } from 'vue'
import store from '@/store'
import Header from './components/Headers'
import Footer from './components/Footer'
import Right from './components/right'
import { getConfig } from '@/api/index'
import { setItem, getItem } from '@/utils/storage'
import { ElMessage } from 'element-plus'
getConfig().then((res) => {
  res.rows.forEach((item) => {
    setItem(item.configKey, item.configValue)
     setItem(item.configKey+".remark", item.remark)
  })
})
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.scss';
.slide-left-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-left-enter-to {
  transform: translateX(0px);
}

.slide-left-leave-from {
  transform: translateX(0);
}

.slide-left-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 30s;
}
</style>
