// import { MAIN_COLOR } from '@/constant'
// import { getItem } from '@/utils/storage'

const getters = {
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  /**
   * @returns true 表示已存在用户信息
   */
  hasUserInfo: (state) => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  getMsg: (state) => state.msg.message,
  getQue: (state) => state.msg.question
}
export default getters
