import { createApp, ref } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
// element-plus ^1.3.0-beta.5引用方式
// import zhLocale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
// element-plus ^1.0.7-beta.70引用方式 废弃
// import zhLocale from 'element-plus/es/locale/lang/zh-cn'
// import 'element-plus/lib/theme-chalk/index.css'
// 导入 svgIcon
import installIcons from '@/icons'
// filter
import installFilter from '@/filters'
// 导入全局样式
import './styles/global.scss'
// css动画库
import 'animate.css'
// 导入权限控制模块
import './permission'
const app = createApp(App)
// element-plus国际化配置废弃
// ElementPlus.useLang = (app, ref, locale) => {
//   const template = (str, option) => {
//     if (!str || !option) return str
//     return str.replace(/\{(\w+)\}/g, (_, key) => {
//       return option[key]
//     })
//   }
//   // 注入全局属性,子组件都能通过inject获取
//   app.provide('ElLocaleInjection', {
//     lang: ref(locale.name),
//     locale: ref(locale),
//     t: (...args) => {
//       const [path, option] = args
//       let value
//       const array = path.split('.')
//       let current = locale
//       for (let i = 0, j = array.length; i < j; i++) {
//         const property = array[i]
//         value = current[property]
//         if (i === j - 1) return template(value, option)
//         if (!value) return ''
//         current = value
//       }
//     }
//   })
// }
// ElementPlus.useLang(app, ref, zhLocale)

installIcons(app)
installFilter(app)
app
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(document.dispatchEvent(new Event('render-event')))
  .mount('#app')
