<template>
  <!-- 这是layout => user -->
  <div class="userBg">
    <div class="user-operation-panel">
      <div class="panel-header">
        <div class="showAvatar">
          <el-avatar :size="80" :src="info?.avatar"></el-avatar>
          <div class="nickname" style="margin-bottom: 20px;">{{ info?.nickName }}</div>
        </div>
        <ul class="user-meun">
          <li
          style="cursor: pointer;"
            :class="{ active: activeIndex === ol.name }"
            v-for="(ol, index) in operationList"
            :key="ol.id"
            @click="handleSelect(ol, index)"
          >
            <span v-if="ol.inform" class="red_spot"></span>
            {{ ol.title }}
          </li>
        </ul>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { setItem, getItem } from '../../utils/storage'
import { getNoticeList, getQuizList } from '../../api/user'
const router = useRouter()
const store = useStore()
const info = ref('')
const operationList = ref([
  {
    id: 1648,
    title: '我的课程',
    name: '/user/myCourses'
  },
    {
    id: 4561,
    title: '答疑管理',
    name: '/user/answeringQuestion',
    inform: false
  },
  {
    id: 2165,
    title: '我的笔记',
    name: '/user/note'
  },
  {
    id: 5516,
    title: '学分管理',
    name: '/user/credit'
  },
  {
    id: 6564,
    title: '订单管理',
    name: '/user/orderManagement'
  },
    {
    id: 3651,
    title: '消息中心',
    name: '/user/messageCenter',
    inform: false
  },
  {
    id: 7425,
    title: '账号管理',
    name: '/user/accountManagement'
  }
])

const activeIndex = ref(getItem('userKey') || operationList.value[0].name)
const handleSelect = (e) => {
  activeIndex.value = e.name
  setItem('userKey', activeIndex.value)
  router.push(e.name)
}
getNoticeList({}).then((res) => {
  res.rows.forEach((item) => {
    if (item.issee == 0) store.commit('msg/setMsg', true)
  })
})
getQuizList({}).then((res) => {
  res.rows.forEach((item) => {
    if (item.issee == 0) store.commit('msg/setQuestion', true)
  })
})

watchEffect(() => {
  info.value = store.getters.userInfo
  activeIndex.value = router.currentRoute.value.path
})
watch(
  () => store.getters.getMsg,
  (v) => {
    operationList.value[5].inform = v
  },
  {
    immediate: true
  }
)
watch(
  () => store.getters.getQue,
  (v) => {
    operationList.value[1].inform = v
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
@import '@/common/rem';
.userBg {
  background: #f3f5f7;
  padding: rem(70) 0;
  min-width: 1200px;
  min-height: calc(100vh - (70px + 140px + 260px));
  .user-operation-panel {
    width: 1120px;
    margin: 0 auto;
    padding: 0 40px;
    background: #ffffff;
    .panel-header {
      padding-top: 40px;
      display: flex;
      font-size: rem(18);
      align-items: center;
      text-align: center;
      .showAvatar {
        .el-avatar {
          box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.05);
        }
        .nickname {
          font-size: rem(18);
          font-weight: bold;
          color: #333333;
        }
      }
      .user-meun {
        flex-grow: 1;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        // margin-left: rem(80);
        justify-content: flex-end;
        li {
          width: rem(140);
          height: rem(50);
          line-height: rem(50);
          font-weight: bold;
          position: relative;
          .red_spot {
            background: red;
            width: 10px;
            display: block;
            position: absolute;
            right: 10px;
            border-radius: 50%;
            height: 10px;
          }
        }
        .active {
          border-radius: rem(56);
          background: #293e73;
          color: #ffffff;
        }
      }
    }
  }
}
.is-active{
  color: #6e7eb8 !important;
}
.el-tabs__active-bar{
  background-color: #6e7eb8 !important;
}
.el-tabs__nav-wrap::after{
  height: 1px !important;
}
</style>
