import request from '@/utils/request'

// 登录方法
export function login(data) {
  return request({
    url: '/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}
// 手机验证码登录/注册
export function phoneLogin(data) {
  return request({
    url: '/login/phone',
    method: 'post',
    data
  })
}
//  获取微信登录链接
export function getWXLoginUrl(params) {
  return request({
    url: '/mp/getAuthUrl',
    method: 'GET',
    params
  })
}
// 获取token
export function getToken(params) {
  return request({
    url: '/mp/getToken',
    method: 'GET',
    params
  })
}
// 微信静默登录
// export function signByUnionidLogin(params) {
//   return request({
//     url: '/mp/signByUnionid',
//     method: 'GET',
//     params
//   })
// }
// 获取token
export function signByUnionidLogin(params) {
  return request({
    url: '/mp/signByUnionid',
    method: 'GET',
    params
  })
}
// 邮件验证码登录/注册
export function emailLogin(data) {
  return request({
    url: '/login/mail',
    method: 'post',
    data
  })
}
// 发送手机验证码
export function getPhoneCode(data) {
  return request({
    url: '/send/smsCode',
    method: 'post',
    data
  })
}
// 验证手机验证码是否正确
export function checkPhoneCode(data) {
  return request({
    url: '/send/auCode',
    method: 'post',
    data
  })
}
// 发送邮件验证码
export function getEmailCode(params) {
  return request({
    url: '/api/course/eamilSend',
    method: 'GET',
    params
  })
}
// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}
// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}
// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}
// 获取图片验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
