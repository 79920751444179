import axios from 'axios'
import store from '@/store'
import errorCode from '@/utils/errorCode'
import { getItem } from '@/utils/storage'
import { TOKEN } from '@/constant'
import { ElMessageBox, ElMessage } from 'element-plus'
import { tansParams } from '@/utils/ruoyi'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getItem(TOKEN) && !isToken) {
      config.headers.Authorization = 'Bearer ' + getItem(TOKEN) // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params)
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode.default
    // 二进制数据则直接返回
    if (
      res.request.responseType === 'blob' ||
      res.request.responseType === 'arraybuffer'
    ) {
      return res.data
    }
    if (code === 401) {
      ElMessageBox.confirm('登录已失效，请重新登录', '系统提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        if (res === 'confirm') {
          // location.href =  '/login'
        store.dispatch('user/logout').then(() => {
          location.href =  '#/login'
        })
        }
      })
      // store.dispatch('user/logout').then(() => {
      //   location.href = process.env.VUE_APP_CONTEXT_PATH + 'index'
      // })
      return Promise.reject(
        new Error('无效的会话，或者会话已过期，请重新登录。')
      )
    } else if (code === 500) {
      ElMessage({
        message: msg,
        type: 'error'
      })
      return Promise.reject(new Error(msg))
    } else {
      return res.data
    }
  },
  (error) => {
    let { message } = error
    if (message === 'Network Error') {
      message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    ElMessage({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
