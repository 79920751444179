<template>
  <div
    class="navigationBar"
    :style="{ backgroundColor: backgroundColor, color: textColor }"
  >
    <div class="content">
      <div class="logo pointer" @click="goto('/index')">
        <svg-icon icon="pc_logo" className="login-icon" />
        <!-- <span> {{ showValue }}</span> -->
      </div>
      <el-menu
        class="el-menu-demo"
        :textColor="textColor"
        :default-active="activeIndex"
        :activeTextColor="activeTextColor"
        :backgroundColor="backgroundColor"
        :router="true"
        mode="horizontal"
      >
        <el-menu-item v-for="nav in navBar" :key="nav.id" :index="nav.path">
          {{ nav.name }}
        </el-menu-item>
      </el-menu>
      <div class="contact">
        <!-- <a href="tel:400-800-1106"> -->
          <svg-icon icon="phone" size="24" color="#a3a3a3" style="margin-right: 5px;" />400-800-1106
        <!-- </a> -->
      </div>
      <div class="showUserInfo pointer">
        <div v-if="!userinfo?.avatar">
          <div @click="goto('/login')">登录</div>
        </div>
        <el-popover v-else trigger="hover" width="5rem" placement="bottom">
          <template #reference>
            <div class="row">
              <el-avatar
                :src="userinfo.avatar"
                @click="goto('/user')"
              ></el-avatar>
              <div>{{ userinfo.title }}</div>
            </div>
          </template>
          <template #default>
            <div class="text-center operation">
              <a>
              <p class="ma-bo-10" @click="alterAvatar()">修改头像</p>
              </a>
              <a>
              <p class="ma-bo-10" @click="logout()">退出登录</p>
              </a>
            </div>
          </template>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { PhoneFilled } from '@element-plus/icons'
import { getItem } from '../../../../utils/storage'
import { getConfigKey } from '../../../../api/index'

const store = useStore()
const activeIndex = ref(props.navBar[0].id)
var color = '#FFFFFF'
const router = useRouter()
const textColor = ref(color)
const activeTextColor = ref(color)
const backgroundColor = ref('#293E73')
const emit = defineEmits(['updatas'])
// const showValue = ref('')

const props = defineProps({
  userinfo: {
    type: Object,
    default: () => {
      return {}
    }
  },
  navBar: {
    type: Array,
    default: () => {
      return []
    }
  }
})
// 当前使用导航
// getConfigKey('site.name').then((res) => {
//   showValue.value = res.msg
// })

// 导航
const goto = (url) => {
  router.push({
    path: url
  })
}
const logout = async () => {
  await store.dispatch('user/logout')
}
const alterAvatar = () => {
  emit('updatas', true)
}
</script>

<style lang="scss" scoped>
@import '@/common/rem';
.navigationBar {
  min-width: 1200px;
  height: rem(70);
  line-height: rem(70);
  overflow: hidden;
  .operation {
    text-align: center;
    p {
      border-bottom: 1px solid #eee;
      margin-bottom: 100px;
    }
  }
.el-popper{
  width: 3rem;
}
  .content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    font-size: rem(18);
    font-weight: normal;
    align-items: center;
    justify-content: space-between;
    .logo {
      font-size: rem(36);
      font-weight: bold;
      display: flex;
      .login-icon {
        width: 160px;
        height: 70px;
      }
    }
    .el-avatar--large{
      --el-avatar-size: 50px;
    }
    .el-menu-demo {
      border: none;
      height: rem(70);
      * {
        border: none;
      }
      .el-menu-item {
        line-height: rem(70);
        height: rem(70);
        margin: 0 30px !important;
        font-size: rem(18);
      }
    }
    .contact {
      a {
        color: #a3a3a3;
      }
    }
    .showUserInfo {
      display: flex;
      span {
        margin: auto;
      }
      > .el-dropdown {
        display: flex;
        align-content: flex-end;
        justify-content: center;
      }
    }
  }
  ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
  ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    background-color: transparent !important ;
  }
  ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    background-color: transparent !important ;
  }
}
</style>
