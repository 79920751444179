import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import basics from '@/layout/index'
import blank from '@/layout/blank'
import user from '@/layout/user'

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/',
    redirect: '/index',
    component: basics,
    children: [
      // 基础布局下的页面
      {
        path: '/index',
        name: 'index',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/index/index.vue'),
        meta: {
          // 标题设置
          title: '首页'
        }
      },
      {
        path: '/login',
        name: 'login',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
        meta: {
          title: '登录'
        }
      },
      {
        path: '/changePassword',
        name: 'changePassword',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/views/changePassword/index.vue'
          ),
        meta: {
          title: '修改密码'
        }
      },
      {
        path: '/curriculum',
        name: 'curriculum',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/curriculum/index.vue'),
        meta: {
          title: '我的课程'
        }
      },
      {
        path: '/curriculum/details/:id',
        name: 'curriculumDetails',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/views/curriculum/details/index.vue'
          ),
        meta: {
          title: '课程详情'
        }
      },
      {
        path: '/curriculum/purchase/:id',
        name: 'curriculumPurchase',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/views/curriculum/purchase/index.vue'
          ),
        meta: {
          title: '购买课程',
          requireAuth: true
        }
      },
      {
        path: '/download',
        name: 'download',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/download/index.vue'),
        meta: {
          title: '下载'
        }
      },
      {
        path: '/consociation',
        name: 'consociation',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/views/consociation/index.vue'
          ),
        meta: {
          title: '商务合作'
        }
      },
      {
        path: '/selective',
        name: 'selective',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/selective/index.vue')
      },
      {
        path: '/declare',
        name: 'declare',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/declare/index.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: '/user',
        name: 'user',
        component: user,
        redirect: '/user/myCourses',
        children: [
          {
            path: '/user/index',
            name: 'user',
            component: () =>
              import(/* webpackChunkName: "user" */ '@/views/user/index.vue'),
            meta: {
              title: '个人中心',
              requireAuth: true
            }
          },
          {
            path: '/user/myCourses',
            name: 'myCourses',
            component: () =>
              import(
                /* webpackChunkName: "user" */ '@/views/myCourses/index.vue'
              ),
            meta: {
              title: '我的课程',
              requireAuth: true
            }
          },
          {
            path: '/user/note',
            name: 'note',
            component: () =>
              import(/* webpackChunkName: "user" */ '@/views/note/index.vue'),
            meta: {
              title: '我的笔记',
              requireAuth: true
            }
          },
          {
            path: '/user/messageCenter',
            name: 'messageCenter',
            component: () =>
              import(
                /* webpackChunkName: "user" */ '@/views/messageCenter/index.vue'
              ),
            meta: {
              title: '消息中心',
              requireAuth: true
            }
          },
          {
            path: '/user/answeringQuestion',
            name: 'answeringQuestion',
            component: () =>
              import(
                /* webpackChunkName: "user" */ '@/views/answeringQuestion/index.vue'
              ),
            meta: {
              title: '问答中心',
              requireAuth: true
            }
          },
          {
            path: '/user/credit',
            name: 'credit',
            component: () =>
              import(/* webpackChunkName: "user" */ '@/views/credit/index.vue'),
            meta: {
              title: '学分管理',
              requireAuth: true
            }
          },
          {
            path: '/user/orderManagement',
            name: 'orderManagement',
            component: () =>
              import(
                /* webpackChunkName: "user" */ '@/views/orderManagement/index.vue'
              ),
            meta: {
              title: '订单管理',
              requireAuth: true
            }
          },
          {
            path: '/user/accountManagement',
            name: 'accountManagement',
            component: () =>
              import(
                /* webpackChunkName: "user" */ '@/views/accountManagement/index.vue'
              ),
            meta: {
              title: '账户管理',
              requireAuth: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/learn',
    redirect: '/learn/index',
    component: blank,
    children: [
      {
        path: '/learn/video',
        name: 'video',
        component: () =>
          import(
            /* webpackChunkName: "learn" */ '@/views/learn/video/index.vue'
          ),
        meta: {
          title: '视频学习',
          requireAuth: true
        }
      },
      {
        path: '/learn/video2',
        name: 'video2',
        component: () =>
          import(
            /* webpackChunkName: "learn" */ '@/views/learn/video/video.vue'
          ),
        meta: {
          title: '视频学习2',
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/login/end',
    redirect: '/login/suc',
    component: blank,
    children: [
      // 空白布局使用的页面
      {
        path: '/login/suc',
        name: 'loginSuc',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/loginSuc/suc.vue')
      },
      {
        path: '/login/wxaltsuc',
        name: 'wxaltsuc',
        component: () =>
          import(/* webpackChunkName: "suc" */ '@/views/loginSuc/wxaltsuc.vue')
      },
      {
        path: '/login/iscuc',
        name: 'iscuc',
        component: () =>
          import(/* webpackChunkName: "suc" */ '@/views/loginSuc/iscuc.vue')
      },
      {
        path: '/login/fail',
        name: 'fail',
        component: () =>
          import(/* webpackChunkName: "suc" */ '@/views/loginSuc/fail.vue')
      },
      {
        path: '/login/verify',
        name: 'verify',
        component: () =>
          import(/* webpackChunkName: "suc" */ '@/views/loginSuc/verify.vue')
      }
    ]
  },
  {
    path: '/*',
    redirect: '/404',
    component: blank,
    children: [
      // 空白布局使用的页面
      {
        path: '/404',
        name: '404',
        component: () =>
          import(/* webpackChunkName: "learn" */ '@/views/error/404.vue')
      }
    ]
  }
]

const router = createRouter({
  history:
    process.env.NODE_ENV === 'production'
      ? createWebHistory()
      : createWebHashHistory(),
  routes: publicRoutes
})

router.beforeEach(async (to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
