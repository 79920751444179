import { TOKEN } from '@/constant'
import {
  login,
  getInfo,
  phoneLogin,
  emailLogin,
  signByUnionidLogin
} from '@/api/login'
import router from '@/router'
import { setItem, getItem, removeItem } from '@/utils/storage'
//import { assert } from 'node_modules/@vue/compiler-core'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: {}
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    login(state, userInfo) {
      const { username, methd, password, code, uuid, phone, mail } = userInfo
      // addressee
      if (methd === 'account') {
        return new Promise((resolve, reject) => {
          login({ username, password, code, uuid, mode: 1 })
            .then((res) => {
              this.commit('user/setToken', res.data.token)
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        })
      } else if (methd === 'wx') {
        let { unionid } = getItem('userInfo')
        return new Promise((resolve, reject) => {
          signByUnionidLogin({ unionid, mode: 1 })
            .then((res) => {
              if (res.code === 200) {
                state.dispatch('getUserInfo')
              }
              this.commit('user/setToken', res.data.token)
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        })
      } else if (methd === 'phone') {
        return new Promise((resolve, reject) => {
          phoneLogin({ phone, code, mode: 1 })
            .then((res) => {
              this.commit('user/setToken', res.data.token)
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        })
      } else if (methd === 'emali') {
        return new Promise((resolve, reject) => {
          emailLogin({ mail, code, mode: 1 })
            .then((res) => {
              this.commit('user/setToken', res.data.token)
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        })
      }
    },
    async getUserInfo({ commit, state }) {
      const res = await getInfo()
      const user = res.data.user
      const avatar = user.avatar
        ? user.avatar
        : require('@/assets/image/profile.jpg')
      user.avatar = avatar
      this.commit('user/setUserInfo', user)
      setItem('userInfo', user)
      return res
    },
    logout() {
      this.commit('user/setToken', '')
      this.commit('user/setUserInfo', {})
      removeItem(TOKEN)
      removeItem('videoInfo')
      removeItem('dfahkhkjsdgfhajhjs')

      router.push('/index')
    }
  }
}
