// token
export const TOKEN = 'token'
// token 时间戳
export const TIME_STAMP = 'timeStamp'
// 本地设置超时时长 (1小时 * 三天)
export const TOKEN_TIMEOUT_VALUE = 3600000 * 72
// 国际化
export const LANG = 'language'
// 主题色保存的 key
export const MAIN_COLOR = 'mainColor'
// 默认色值
export const DEFAULT_COLOR = ''
// tags
export const TAGS_VIEW = 'tagsView'
// AD 时间戳
export const TIME_STAMP_AD = 'ADTimeStamp'
