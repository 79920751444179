<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>
<script setup>
import { ref } from 'vue'
import { getSeoSet } from './api/index'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
const locale = ref(zhCn)

getSeoSet().then((res) => {
  let metaDom = document.createElement('meta')
  let metaDom2 = document.createElement('meta')

  metaDom.name = 'keywords'
  metaDom.content = res.rows[0].keyword

  metaDom2.name = 'description'
  metaDom2.content = res.rows[0].describeInfo

  document.head.append(metaDom)
  document.head.append(metaDom2)
})
</script>
