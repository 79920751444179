<template>
  <div>
    <Advertising
      @click="clickAd"
      v-if="total!=0"
      :show="isCheckAD()"
      :isTime="headData?.adExpire && isOver"
      :propagandaText="headData?.adImg"
      model="picture"
      :endTime="headData.adExpire"
    ></Advertising>
    <navigation-bar
      :userinfo="info"
      :navBar="navigationList"
      @updatas="clickAvatar"
    ></navigation-bar>
  </div>
  <el-dialog v-model="dialogVisible" title="修改头像" width="800px">
    <div class="cropperBox">
      <div style="width: 350px; height: 350px">
        <vue-cropper
          ref="cropperImg"
          :img="option.img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :full="option.full"
          :fixedBox="option.fixedBox"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :centerBox="option.centerBox"
          :height="option.height"
          :infoTrue="option.infoTrue"
          :maxImgSize="option.maxImgSize"
          :enlarge="option.enlarge"
          :mode="option.mode"
          @realTime="realTime"
          @imgLoad="imgLoad"
        ></vue-cropper>
      </div>
      <div class="show-preview">
        <div :style="previews.div" class="preview">
          <img :src="previews.url" :style="previews.img" />
          <div style="color: black">头像示例</div>
        </div>
      </div>
    </div>
    <div class="ma-to-30 row alc">
      <el-upload
        ref="upload"
        class="upload-demo"
        :action="fileUrl"
        :limit="1"
        :show-file-list="false"
        :on-exceed="handleExceed"
        :http-request="() => {}"
        :before-upload="beforeUpload"
      >
        <!-- :auto-upload="false" :before-upload="beforeUpload" -->
        <template #default>
          <el-button type="primary">选择图片</el-button>
        </template>
      </el-upload>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitUpload">修改</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import 'vue-cropper/dist/index.css'
import { VueCropper } from 'vue-cropper'
import { adList } from '@/api/index'
import { isCheckAD } from '../../../../utils/auth'
import navigationBar from '../NavigationBar'
import Advertising from '../Advertising'
import { ElMessage } from 'element-plus'
import { alterAvatar } from '../../../../api/user'
const store = useStore()
const cropperImg = ref(null)
const upload = ref(null)
const fileUrl = ref('/system/user/profile/avatar')
const info = ref({})
const previews = ref({})
const isOver = ref(true)
const option = ref({
  img: store.getters.userInfo.avatar, //裁剪图片的地址
  outputSize: 1, // 裁剪生成图片的质量(可选0.1 - 1)
  outputType: 'jpeg', // 裁剪生成图片的格式（jpeg || png || webp）
  info: true, // 图片大小信息
  canScale: false, // 图片是否允许滚轮缩放
  autoCrop: true, // 是否默认生成截图框
  autoCropWidth: 200, // 默认生成截图框宽度
  autoCropHeight: 200, // 默认生成截图框高度
  fixed: true, // 是否开启截图框宽高固定比例
  fixedNumber: [1, 1], // 截图框的宽高比例
  full: false, // false按原比例裁切图片，不失真
  fixedBox: false, //  固定截图框大小，不允许改变
  canMove: true, // 上传图片是否可以移动
  canMoveBox: true, // 截图框能否拖动
  original: false, // 上传图片按照原始比例渲染
  centerBox: false, // 截图框是否被限制在图片里面
  height: true, // 是否按照设备的dpr 输出等比例图片
  infoTrue: true, // true为展示真实输出图片宽高，false展示看到的截图框宽高
  // maxImgSize: 3000, // 限制图片最大宽度和高度
  enlarge: 1, // 图片根据截图框输出比例倍数
  mode: '200px 200px' // 图片默认渲染方式
})
const headData = ref({})
const total = ref(0)
const dialogVisible = ref(false)
const navigationList = ref([
  {
    id: '651',
    name: '首页',
    path: '/index'
  },
  {
    id: '986',
    name: '选课中心',
    path: '/curriculum'
  },
  // {
  //   id: '785',
  //   name: '商务合作',
  //   path: '/consociation'
  // },
  {
    id: '962',
    name: 'App下载',
    path: '/download'
  }
])

adList({ statusId: 1, adType: 0 }).then((res) => {
  total.value = res.total
  headData.value = res.rows[0]
  isOver.value =
    headData.value.adExpire !== moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
})

// 上传预处理
const beforeUpload = (file) => {
  if (file.type.indexOf('image/') == -1) {
    ElMessage({
      message: '文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。',
      type: 'error'
    })
  } else {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      option.value.img = reader.result
      option.value.filename = file.name
    }
  }
}

//实时预览函数
const realTime = (data) => {
  previews.value = data
}

watchEffect(() => {
  info.value = store.getters.userInfo
})
const handleExceed = (files) => {
  upload.value.clearFiles()
  upload.value.handleStart(files[0])
}

const clickAd = (resultValue) => {
  if (!resultValue.underway) {
    return ElMessage({
      message: '活动已结束'
    })
  } else {
    let url = headData.value.adUrl
    let reg = /(http|https):\/\/([\w.]+\/?)\S*/
    if (!reg.test(url)) {
      url = 'http://' + url
    }
    window.open(url, '__target')
  }
}
const clickAvatar = (e) => {
  dialogVisible.value = e
}
const submitUpload = () => {
  cropperImg.value.getCropBlob((data) => {
    let formData = new FormData()
    formData.append('avatarfile', data, option.value.filename)
    alterAvatar(formData).then((response) => {
      dialogVisible.value = false
      store.dispatch('user/getUserInfo')
    })
  })
}
const imgLoad = () => {}
</script>
<style lang="scss" scoped>
.cropperBox {
  display: flex;
  justify-content: space-around;

  .show-preview {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    .preview {
      overflow: hidden;
      background: #cccccc;
    }
  }
}
</style>
