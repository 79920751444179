<template>
  <div class="footer-container">
    <div class="footer-content">
      <!-- <div @click="router.push('/declare')">
        关于六问 | 产品使用 | 服务条款 | 隐私政策 | 商务合作 | 意见反馈 |
        网站地图 | 常见问题
      </div> -->
      <div class="row">
        <a>
        <span
          v-for="item in bottomList"
          :key="item.id"
          @click="router.push('/declare?id=' + item.id)"
        >
          {{ item.bottomTitle }}
        </span>
        </a>
      </div>
      <div class="ma-to-20" ><span v-html="copyright"></span></div>
      <div class="" >
        <a :href="IPCre" style="margin-right: 10px;" rel='nofollow'><span v-html="IPC"></span></a>
        <a :href="GAre" rel='nofollow'>
                <img src="../../../../assets/icon/beian.png" alt="" style="position: absolute;margin-top: 9px;"/>
        <span style="margin-left: 20px;"
          v-html="GA"
        ></span>
          </a>
      </div>
    </div>
    <div class="footer-hint">
      <spen href="tel:010-53671011">违法和不良信息举报电话：010-53671011</spen>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { getEssay, getConfigKey } from '../../../../api/index'
import { getItem } from '../../../../utils/storage'
const router = useRouter()
const bottomList = ref([])
const copyright = ref(getItem('web.on.record'))
const copyrightre = ref(getItem('web.on.record.remark'))
const IPCre = ref(getItem('pc.icp.number.remark'))
const IPC = ref(getItem('pc.icp.number'))
const GA = ref(getItem('pc.ga.number'))
const GAre = ref(getItem('pc.ga.number.remark'))


// getConfigKey('web.on.record').then((res) => {
//   copyright.value = res.msg
// })
// getConfigKey('pc.icp.number').then((res) => {
//   IPC.value = res.msg
// })

getEssay({statusId:1}).then((res) => {
  bottomList.value = res.rows
})
</script>

<style lang="scss" scoped>
@import '@/common/rem';
@mixin content {
  width: 1200px;
  margin: 0 auto;
}
.footer-container {
  bottom: 0;
  width: 100%;
  min-width: 1200px;
  z-index: 9999;
  overflow: hidden;
  text-align: center;
  color: #999999;
  line-height: 22px;
  margin-bottom: 0;
  background: #262933;
  .footer-content {
    font-size: rem(14);
    height: 180px;
    @include content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    div {
      line-height: rem(40);
    }
    .row {
      text-align: center;
      font-size: rem(16);
      color: #ffffff;
      display: flex;
      justify-content: center;
      span {
        padding: 0 1rem;
        line-height: 1rem;
        border-right: 1px solid #fff;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .footer-hint {
    height: 80px;
    line-height: 80px;
    background-color: #25252f;
    p {
      margin: 0 auto;
    }
  }
}
</style>
