import { TIME_STAMP, TOKEN_TIMEOUT_VALUE, TIME_STAMP_AD } from '@/constant'
import { setItem, getItem } from '@/utils/storage'
import moment from 'moment'
moment.locale('zh-en')
// token
// ----------------------------------------------
/**
 * 获取时间戳
 */
export function getTimeStamp() {
  return getItem(TIME_STAMP)
}
/**
 * 设置时间戳
 */
export function setTimeStamp() {
  setItem(TIME_STAMP, Date.now())
}
/**
 * 是否超时
 */
export function isCheckTimeout() {
  // 当前时间戳
  var currentTime = Date.now()
  // 缓存时间戳
  var timeStamp = getTimeStamp()
  return currentTime - timeStamp > TOKEN_TIMEOUT_VALUE
}

// 广告
// ----------------------------------------------
/**
 * 获取时间戳
 */
export function getAD() {
  return getItem(TIME_STAMP_AD)
}
/**
 * 设置时间戳
 */
export function setAD() {
  setItem(TIME_STAMP_AD, Date.now())
}
/**
 * 是否超时
 */
export function isCheckAD() {
  // 缓存时间戳
  var timeStamp = getAD()
  // 当前时间戳
  var currentTime = Date.now()
  // 如果没有获取到 关闭的时间就返回true
  // 否则
  // 获取关闭的时间 拿到关闭后的第二天时间
  // 获取第二天零点时间  和 和当前的时间做比较
  // 如果当前时间大于第二天的零点就返回true
  // eslint-disable-next-line
  return timeStamp === null
    ? true
    : Date.parse(
        moment(new Date(moment(new Date(timeStamp)).add(1, 'days').format()))
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
      ) < currentTime
  /* eslint-disable */
}
