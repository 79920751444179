import request from '@/utils/request'
// 用户获取个人课程列表
export function getMyCourseList(params) {
  return request({
    url: '/api/user/course/list',
    method: 'GET',
    params
  })
}
// 获取个人笔记列表
export function getNoteList(params) {
  return request({
    url: '/api/user/note/list',
    method: 'GET',
    params
  })
}
// 删除用户笔记
export function deleteEdit(params) {
  return request({
    url: '/api/user/note/delete',
    method: 'GET',
    params
  })
}
// 用户获取个人学习记录
export function getLearnRecordList(params) {
  return request({
    url: '/api/user/course/log',
    method: 'GET',
    params
  })
}
// 收藏课程记录列表
export function getcollectList(params) {
  return request({
    url: '/api/userCollection/list',
    method: 'GET',
    params
  })
}
// 删除收藏课程记录
export function delcollect(params) {
  return request({
    url: '/api/userCollection/delect',
    method: 'GET',
    params
  })
}
// 获取个人提问列表
export function getQuizList(params) {
  return request({
    url: '/api/user/course/problem',
    method: 'GET',
    params
  })
}
// 获取问答内容列表
export function getQuizDetail(params) {
  return request({
    url: '/api/user/course/problemCom',
    method: 'GET',
    params
  })
}
// 用户获取个人订单列表
export function getOrderList(params) {
  return request({
    url: '/api/orderInfo/list',
    method: 'GET',
    params
  })
}
// 用户获取学分记录
export function getFractionList(params) {
  return request({
    url: '/api/user/course/fractionlist',
    method: 'GET',
    params
  })
}
// 获取用户个人通知消息列表
export function getNoticeList(params) {
  return request({
    url: '/system/notice/userList',
    method: 'GET',
    params
  })
}
// 通过老密码验证修改密码
export function alterPass(params) {
  return request({
    url: '/system/user/profile/updatePwd',
    method: 'PUT',
    params
  })
}
// 修改个人信息
export function alterInfo(data) {
  return request({
    url: '/system/user/profile',
    method: 'PUT',
    data
  })
}
// 修改头像
export function alterAvatar(data) {
  return request({
    url: '/system/user/profile/avatar',
    method: 'POST',
    data
  })
}
//  发送邮件验证码/已登录绑定邮箱用
export function getEmailCodeEnd(params) {
  return request({
    url: '/api/course/eamilSend/bing',
    method: 'GET',
    params
  })
}

// 检验邮箱验证码并绑定
export function checkEmail(params) {
  return request({
    url: '/system/user/checkEamilCode',
    method: 'GET',
    params
  })
}
// 修改密码
export function alterPasst(params) {
  return request({
    url: '/system/user/profile/update/password',
    method: 'GET',
    params
  })
}
// 新增用户笔记
export function addNote(data) {
  return request({
    url: '/api/user/note/add',
    method: 'POST',
    data
  })
}

// 修改用户笔记
export function alterNote(data) {
  return request({
    url: '/api/user/note/modify',
    method: 'POST',
    data
  })
}
//
//  用户新增提问问题
export function addQuestion(data) {
  return request({
    url: '/api/user/course/problem/add',
    method: 'POST',
    data
  })
}
// 用户导出笔记
export function exportNote(params) {
  return request({
    url: '/api/user/note/export',
    method: 'GET',
    params,
    responseType: 'blob'
  })
}
// 用户导出笔记
export function exportAllNote(params) {
  return request({
    url: '/api/user/note/exportList',
    method: 'GET',
    params,
    responseType: 'blob'
  })
}
// 绑定换绑微信
export function altWX(params) {
  return request({
    url: '/mp/getBindAuthUrl',
    method: 'GET',
    params
  })
}
// 用户注销账号
export function delaccount(params) {
  return request({
    url: '/system/user/profile/cancellation',
    method: 'GET',
    params
  })
}
// 获取用户学习统计信息
export function getStatistics(params) {
  return request({
    url: '/system/user/statistics/' + params,
    method: 'GET'
  })
}
