export default {
  namespaced: true,
  state: () => ({
    message: false,
    question: false
  }),
  mutations: {
    setMsg(s, v) {
      s.message = v
    },
    setQuestion(s, v) {
      s.question = v
    }
  }
}
